import React from 'react';
import Icon from '../Icon';
import { Container, Grid, GridItem, Tooltip } from './styles/index.styled';

const IconGrid = ({ heading, icons }) => (
	<Container>
		<h5 className="text-lg font-black tracking-wide text-gray-800 md:text-left xl:text-xl">
			{heading}
		</h5>
		<Grid>
			{icons?.map(i => (
				<GridItem key={i?.id}>
					<Tooltip className="text-xs font-bold c-tooltip">
						{i?.internalName}
					</Tooltip>
					<Icon type={i?.iconType} icon={i?.icon} classes="icon tooltip" />
				</GridItem>
			))}
		</Grid>
	</Container>
);

export default IconGrid;
