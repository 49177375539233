import styled from 'styled-components';
import AboutBackground from '../../../images/about_bg.webp';

export const Section = styled.section`
	width: 100%;
	height: auto;
	padding: 96px 0px;
	background-image: url(${AboutBackground});
	background-size: cover;
	background-repeat: no-repeat;
	@media (max-width: 992px) {
		background-image: none;
		padding: 72px 0px;
	}
`;
export const Wrapper = styled.div`
	width: 100%;
	height: auto;
	padding: 0px 40px;
	margin: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 992px) {
		flex-direction: column-reverse;
		padding: 0px 15px;
	}
`;
export const Container = styled.div`
	width: 100%;
	height: auto;
	margin: 0px;
	${props => props?.styles}
	@media (max-width: 992px) {
		padding: 0px;
		&:last-child {
			margin-bottom: 32px;
		}
	}
`;
