import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: auto;
	padding: 15px 0px;
`;
export const Grid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(12, minmax(0, 1fr));
	@media (max-width: 768px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
`;
export const GridItem = styled.div`
	position: relative;
	width: auto;
	height: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	svg {
		width: 100%;
		height: 100%;
	}
	&:hover {
		.c-tooltip {
			opacity: 1;
		}
	}
`;
export const Tooltip = styled.span`
	width: auto;
	height: auto;
	padding: 4px;
	border: 1px solid black;
	color: white;
	background: #1f2937;
	border-radius: 4px;
	white-space: nowrap;
	z-index: 50;
	opacity: 0;
	pointer-events: none;
	transition: all 250ms ease-in-out;
`;
