import React from 'react';
import Image from '../../Image';
import Text from '../../Typography';
import IconGrid from '../../IconGrid';
import { Section, Wrapper, Container } from '../styles/index.styled';

const About = ({ data }) => (
	<Section id={data.id}>
		<Wrapper>
			<Container styles="max-width: 680px;">
				{data?.featuredContent && (
					<Image
						src={data?.featuredContent?.image?.gatsbyImageData}
						alt={data?.featuredContent?.image?.alt}
						width="100%"
						height="100%"
					/>
				)}
			</Container>
			<Container styles="max-width: 950px; padding: 0px 48px;">
				{data?.headingKicker && (
					<Text Element="h5" classes="mb-2">
						{data?.headingKicker}
					</Text>
				)}
				{data?.heading && (
					<Text Element="h2" classes="mb-5">
						{data?.heading}
					</Text>
				)}
				{data?.subhead && (
					<Text Element="div" classes="mb-5" html={data?.subhead} />
				)}
				{data?.icons && (
					<IconGrid heading="Technical Skills" icons={data?.icons} />
				)}
			</Container>
		</Wrapper>
	</Section>
);

export default About;
